import dynamic from 'next/dynamic'

import {
	Checkbox,
	Color,
	Image,
	Link,
	List,
	Shape,
	Style,
	TextInput
} from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { FolderStructure } from '@/utils/makeswift/constants'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./MegaMenuColumn').then(({ MegaMenuColumn }) => MegaMenuColumn)))
	),
	{
		type: 'mega-menu-column',
		label: `${FolderStructure.UnderDevelopment}/${FolderStructure.MegaMenu}/Mega Menu Column`,
		props: {
			className: Style({ properties: [Style.Width, Style.Margin, Style.Padding] }),
			subtitle: TextInput({ label: 'Heading', defaultValue: 'Heading' }),
			divider: Checkbox({
				label: 'Divider',
				defaultValue: true
			}),
			subnavLinks: List({
				label: 'Sub Items',
				type: Shape({
					type: {
						icon: Image({
							label: 'Icon',
							format: Image.Format.WithDimensions
						}),
						text: TextInput({ label: 'Sub Item', defaultValue: 'Link', selectAll: true }),
						LinkHoverColor: Color({
							label: 'Link hover color',
							defaultValue: '#0d52ff'
						}),
						subtext: TextInput({
							label: 'Sub Item Description',
							defaultValue: '',
							selectAll: true
						}),
						link: Link({ label: 'On click' })
					}
				}),
				getItemLabel(subnavLink) {
					return subnavLink?.text || 'Link'
				}
			})
		}
	}
)
